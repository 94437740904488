import type { TupleToUnion } from 'type-fest'

const appHeadAssets = [
  'favicon-16',
  'favicon-32',
  'apple-touch-icon',
  'safari-pinned-tab',
] as const
export type AppHeadAsset = TupleToUnion<typeof appHeadAssets>

const appAssets = [
  'dashed-border-blue',
  'qf-bg-main',
  'quickframe-logo',
  'quickframe-logo-nav',
] as const
export type AppAsset = TupleToUnion<typeof appAssets>

const appAssetUrlMap: Record<AppAsset | AppHeadAsset, string> = {
  'apple-touch-icon':
    'https://res.cloudinary.com/magicsky/image/upload/v1731440115/apple-touch-icon.png',
  'dashed-border-blue':
    'https://res.cloudinary.com/magicsky/image/upload/v1731443405/dashed-border.svg',
  'favicon-16':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436610/favicon-16x16.png',
  'favicon-32':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436610/favicon-32x32.png',
  'qf-bg-main':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436572/qf-bg-main.jpg',
  'quickframe-logo':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436571/quickframe-logo.svg',
  'quickframe-logo-nav':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436571/quickframe-logo-nav.svg',
  'safari-pinned-tab':
    'https://res.cloudinary.com/magicsky/image/upload/v1731436609/safari-pinned-tab.svg',
}

export const getHeadAssetUrl = (asset: AppHeadAsset) => appAssetUrlMap[asset]

export const getAssetUrl = (asset: AppAsset) => appAssetUrlMap[asset]
