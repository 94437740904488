'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  CenteredLayout,
  Input,
  LoginBox,
} from '@mntn-dev/auth-components'
import { useFlags } from '@mntn-dev/flags-client'
import { useTranslation } from '@mntn-dev/i18n'

import { getAssetUrl } from '@mntn-dev/app-assets'
import { trpcReactClient } from '~/app/_trpc/trpc-react-client.ts'
import { useStore } from '~/store'
import { type FormSchemaType, formSchema, onSubmit } from './form-handler.ts'

const LoginPage = () => {
  const flags = useFlags()
  const lng = useStore((state) => state.lng)
  const { t } = useTranslation(lng, 'login')
  const trpcUtils = trpcReactClient.useUtils()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(
      formSchema({
        restrictedLogins: flags.magicskyRestrictedLogins,
        emailErrorMessage: t('unsupported-email'),
      })
    ),
  })

  const handleSubmitClick = (data: { emailAddress: string }) => {
    setLoading(true)
    onSubmit(trpcUtils.client, data).catch(() => {
      setLoading(false)
      setError(
        'emailAddress',
        { type: 'focus', message: t('unavailable') },
        { shouldFocus: true }
      )
    })
  }

  return (
    <CenteredLayout background="default">
      <LoginBox>
        <LoginBox.Header
          image={{
            url: getAssetUrl('quickframe-logo'),
            alt: t('alt'),
          }}
          title={t('title')}
          subtitle={t('subtitle')}
        />
        <LoginBox.Body>
          <LoginBox.Form onSubmit={handleSubmit(handleSubmitClick)}>
            <LoginBox.FormField>
              <Input
                label={t('email')}
                id="emailAddress"
                hasError={!!errors.emailAddress}
                {...register('emailAddress')}
              />
              {errors.emailAddress && (
                <LoginBox.FormFieldError>
                  {errors?.emailAddress.message ?? ''}
                </LoginBox.FormFieldError>
              )}
            </LoginBox.FormField>
            <LoginBox.FormFooter>
              <Button loading={loading} type="submit">
                {t('continue')}
              </Button>
            </LoginBox.FormFooter>
          </LoginBox.Form>
        </LoginBox.Body>
      </LoginBox>
    </CenteredLayout>
  )
}

export default LoginPage
